.list-view {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    .search {
        padding: 16px;
    }

    .scroll-container {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .sort-toolbar {
            padding: 0px 16px;
            display: flex;

            .filler {
                width: 56px;
                margin-left: 4px;
            }

            .button {
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: $app-text-color;
                border-bottom: 1px solid $app-bg-color-dark;
                cursor: pointer;
                transition: all 0.2s;

                &:hover {
                    border-bottom: 1px solid $primary-01;
                    color: $primary-01;
                }

                &.active {
                    border-bottom: 1px solid $primary-01;
                    color: $primary-01;
                }

                &.checkbox {
                    width: 64px;
                    padding: 0px;
                }

                &.schema {
                    min-width: 96px;
                    margin-left: 4px;
                }

                &.type {
                    min-width: 96px;
                    margin-right: 4px;
                }

                &.geometry {
                    min-width: 56px;
                }
                &.name {
                    flex-grow: 1;
                    min-width: 166px;
                }
                &.long-name {
                    flex-grow: 1;
                    min-width: 266px;
                    margin-left: 4px;
                }
                &.date {
                    min-width: 132px;
                    margin-left: 4px;
                }
                &.roles {
                    margin-left: 4px;
                    width: 92px;
                }
                &.text {
                    margin-left: 4px;
                    min-width: 102px;
                }
                &.table-name {
                    margin-left: 4px;
                    min-width: 332px;
                }
            }
        }

        .item-container {
            margin: 0px 16px;
            overflow: auto;
            flex-grow: 1;
            overflow-y: overlay;
            .item {
                color: $app-text-color;
                user-select: none;
                transition: all 0.2s;
                display: flex;
                cursor: pointer;
                // border-right: 2px solid transparent;

                &:not(:last-child) {
                    margin-bottom: 4px;
                }

                &.selected {
                    color: $primary-01;
                }

                &:hover {
                    color: $primary-01;

                    .overflow-text {
                        color: $primary-01;
                    }

                    svg {
                        color: $primary-01;
                    }
                }

                .container {
                    display: flex;
                    width: 100%;

                    .row-container {
                        flex-grow: 1;
                        background-color: $app-bg-color-light;
                        .row-1 {
                            display: flex;
                            align-items: center;

                            &.checkbox {
                                margin-left: 16px;
                            }

                            .geometry-icon {
                                display: flex;
                                padding: 16px;
                                justify-content: center;
                                align-items: center;
                                background-color: $app-bg-color-light;
                                border-right: 4px solid $app-bg-color;
                            }

                            &.cached {
                                .geometry-icon {
                                    color: $primary-01;
                                }
                            }

                            &.uncached {
                                .geometry-icon {
                                    color: $error-bg;
                                }
                            }

                            &.caching {
                                .geometry-icon {
                                    color: $warning-bg;
                                }
                            }

                            .button {
                                border-left: 4px solid $app-bg-color;
                                display: flex;
                                align-items: center;
                                padding: 16px;
                                transition: all 0.2s;

                                .icon {
                                    font-size: 24px;
                                }

                                &:hover {
                                    color: white;
                                    background-color: $primary-01;
                                }
                            }
                        }

                        .row-2 {
                            height: 50px;
                            padding-left: 16px;
                            display: flex;
                            align-items: center;
                            background-color: #292e38;

                            .icon {
                                font-size: 16px;
                                margin-right: 8px;
                            }
                        }
                    }

                    .name {
                        background-color: $app-bg-color-light;
                        min-width: 150px;
                        display: flex;
                        align-items: center;
                        padding-left: 16px;
                        flex-grow: 1;
                        height: 56px;
                        font-size: 14px;
                        transition: all 0.2s;
                    }

                    .schema {
                        padding: 0px 8px;
                        height: 56px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        border-right: 4px solid $app-bg-color;
                        min-width: 80px;
                        max-width: 80px;
                        overflow: hidden;
                        color: $app-text-color;
                    }

                    .checkbox {
                        min-width: 56px;
                        height: 56px;
                        padding: 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-left: 4px solid $app-bg-color;
                    }

                    .date {
                        padding: 0px 16px;
                        height: 56px;
                        display: flex;
                        align-items: center;
                        justify-items: center;
                        font-size: 14px;
                        border-left: 4px solid $app-bg-color;
                        min-width: 100px;
                        max-width: 100px;
                    }

                    .created {
                        font-size: 12px;
                        margin-right: 24px;

                        &.grow {
                            flex-grow: 1;
                        }
                    }

                    .row-count {
                        border-left: 4px solid $app-bg-color;
                        min-width: 70px;
                        max-width: 70px;
                        padding: 0px 16px;
                        height: 56px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .roles {
                        min-width: 60px;
                        max-width: 60px;
                        padding: 0px 16px;
                        height: 56px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        border-left: 4px solid $app-bg-color;
                    }

                    .cached-status {
                        background-color: $primary-01;
                        color: white;
                        padding: 8px;
                        margin-right: 8px;
                        width: 56px;
                        text-align: center;

                        &.cached {
                            background-color: $primary-01;
                        }

                        &.uncached {
                            background-color: $error-bg;
                        }
                    }
                }
            }

            .no-data-indicator {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
