.uploader {
    padding: 8px;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .container {
        padding: 0px;
        position: relative;
        display: flex;
        justify-content: center;
        .drag-overlay {
            background-color: $primary-01;
            pointer-events: none;
            position: absolute;
            left: 16px;
            right: 16px;
            height: 100%;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;

            h1 {
                color: white;
                pointer-events: none;
            }
        }
    }

    .header {
        display: flex;
        align-items: center;

        height: 48px;
        padding: 0px 16px;

        .action-button {
            margin-left: 4px;
        }
        .title {
            flex-grow: 1;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        padding: 16px;
        overflow: auto;
        height: 100%;

        .actions {
            display: flex;
            margin-bottom: 16px;
            .flex-grow {
                flex-grow: 1;
            }
        }

        .file-uploader {
            width: 300px;
            min-height: 450px;
            display: flex;
            flex-direction: column;
            border-right: 1px solid $primary-01;
            .title {
                margin-bottom: 8px;
            }

            .csv-upload-info {
                padding: 16px;
                height: 100%;
                display: flex;
                flex-direction: column;

                .grow {
                    flex-grow: 1;
                }
            }

            .dataset-upload-info {
                padding: 16px;
                height: 100%;
                display: flex;
                flex-direction: column;

                .grow {
                    flex-grow: 1;
                }
            }

            .file-upload {
                flex-grow: 1;

                .progress-page {
                    height: 300px;
                    background-color: $app-bg-color-light;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .text {
                        text-align: center;
                    }
                }
            }
        }

        .upload-list {
            height: 100%;
            flex-grow: 1;

            .title {
                margin-bottom: 8px;
            }

            .no-uploads {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            .upload {
                &:not(:last-child) {
                    .upload-container {
                        margin-bottom: 16px;
                    }
                }

                .flex-grow {
                    flex-grow: 1;
                }

                .type {
                    height: 56px;
                    width: 56px;
                    display: flex;
                    border-radius: 5px;
                    justify-content: center;
                    align-items: center;
                    background-color: $primary-01;
                    color: white;
                    font-weight: bold;
                    font-size: 28px;
                    margin-right: 4px;
                    cursor: pointer;

                    &.cancelled {
                        background-color: $warning-bg;
                    }

                    &.failed {
                        background-color: $error-bg;
                    }
                }

                .upload-container {
                    padding: 0px;
                    display: flex;
                    align-items: center;
                    .section {
                        margin-right: 8px;
                        min-width: 250px;
                        background-color: white;

                        &.grow {
                            flex-grow: 1;
                        }

                        &.checkbox {
                            display: flex;
                            margin-left: 8px;
                            background-color: $app-bg-color;
                        }
                    }

                    .button-container {
                        display: flex;
                    }
                }

                .uploading-container {
                    .uploading-info {
                        margin-bottom: 16px;

                        display: flex;
                        height: 56px;

                        .section {
                            margin-right: 8px;
                            min-width: 250px;
                        }
                        .status {
                            position: absolute;
                            color: #eba981;
                            height: 100%;
                            width: calc(100% - 20px);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 14px;
                            mix-blend-mode: difference;
                            padding: 0px 10px;
                            text-overflow: ellipsis;
                            word-wrap: break-word;
                            white-space: normal;
                        }

                        .icon {
                            padding: 0px 8px;
                            width: 56px;
                            height: 56px;
                            border-radius: 5px;
                            margin-right: -4px;
                        }

                        .file-name {
                            padding: 16px;

                            background-color: $app-bg-color-light;
                            min-width: 150px;
                            max-width: 150px;
                            text-align: center;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            cursor: pointer;
                            margin-right: 4px;
                        }

                        .error-icon {
                            font-size: 56px;
                            color: $error-bg;
                            cursor: pointer;
                        }

                        .progress {
                            flex-grow: 1;
                            height: 56px;
                            display: flex;
                            position: relative;
                            background-color: #000000;
                            font-weight: bold;
                            mix-blend-mode: difference;
                            .progress-bar {
                                flex-grow: 1;
                                height: 56px;
                                .MuiLinearProgress-barColorPrimary {
                                    background-color: #e1a17a;
                                }
                            }

                            &.warning {
                                background-color: $app-bg-color;
                                mix-blend-mode: unset;
                                .progress-bar {
                                    .MuiLinearProgress-barColorPrimary {
                                        background-color: #fce2e1;
                                    }
                                }

                                .status {
                                    color: white;

                                    mix-blend-mode: unset;
                                }
                            }

                            &.error {
                                background-color: $app-bg-color;
                                mix-blend-mode: unset;
                                .progress-bar {
                                    .MuiLinearProgress-barColorPrimary {
                                        background-color: #fce2e1;
                                    }
                                }

                                .status {
                                    color: $white;

                                    mix-blend-mode: unset;
                                }
                            }
                        }
                    }
                }

                .file-upload-button {
                    margin-left: 4px;
                    min-width: 56px;
                    height: 56px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $primary-01;
                    color: white;
                    cursor: pointer;
                    transition: all 0.2s;

                    &:hover {
                        filter: grayscale(50%);
                    }

                    &.cancelled {
                        background-color: $warning-bg;
                    }

                    &.failed {
                        background-color: $error-bg;
                    }
                }
            }
        }
    }
}

.file-upload {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .multi-switch {
        margin-bottom: 8px;
    }

    .file-upload-box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $app-bg-color-light;
        cursor: pointer;
        transition: all 0.2s;
        flex-grow: 1;
        height: 250px;

        .pointer-events-none {
            pointer-events: none;
        }

        &.dragging {
            background-color: $primary-01;

            .text {
                color: white;
            }

            .icon {
                color: white;
            }

            .file-name {
                color: white;
            }
        }

        &:hover {
            background-color: $primary-01;

            .text {
                color: white;
            }

            .icon {
                color: white;
            }

            .file-name {
                color: white;
            }
        }

        .text {
            color: $app-text-color;
            text-align: center;
        }

        .icon {
            margin-top: 8px;
            font-size: 36px;
            color: $primary-01;
        }

        .file-name {
            margin-top: 8px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $primary-01;
        }

        .button {
            margin-top: 16px;
        }
    }

    .file-upload-box2 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $app-bg-color-light;
        cursor: pointer;
        transition: all 0.2s;
        flex-grow: 1;
        height: 250px;

        .pointer-events-none {
            pointer-events: none;
        }

        &.dragging {
            background-color: $primary-01;

            .text {
                color: white;
            }

            .icon {
                color: white;
            }

            .file-name {
                color: white;
            }
        }

        .text {
            color: $app-text-color;
            text-align: center;
        }

        .icon {
            margin-top: 8px;
            font-size: 36px;
            color: $primary-01;
        }

        .file-name {
            margin-top: 8px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $primary-01;
        }

        .button {
            margin-top: 16px;
        }
    }
}
