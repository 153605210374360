// BRAND COLORS

$primary-01: #024f79;
$primary-02: #67a9c1;
$primary-03: #00749a;
$primary-04: #004166;

$secondary-01: #d3e2ea;
$accent-01: #0393e2;

// NEUTRAL COLORS

$black: #000;
$neutral-01: #222222;
$neutral-02: #616c6f;
$neutral-03: #848f94;
$neutral-04: #e3e4e5;
$neutral-05: #f0f0f0;
$neutral-06: #f5f7f8;
$white: #fff;

// SEMANTIC COLORS

$semantic-01: #149b62;
$semantic-02: #ec3c3e;
$semantic-03: #f67500;
$semantic-04: #f5ad00;

// COMPONENTS COLORS

$app-bg-color: $neutral-06;
$app-bg-color-light: $white;
$app-bg-color-dark: $neutral-03;

$success-bg: $semantic-01;
$error-bg: $semantic-02;
$warning-bg: $semantic-04;
$info-bg: $secondary-01;

$success-text-color: $white;
$error-text-color: $white;
$warning-text-color: $neutral-01;
$info-text-color: $primary-01;

$app-text-color: $neutral-01;
$app-text-color-light: $neutral-02;
$muted-color: $neutral-03;
$icon-color: $app-text-color;
$icon-color-light: $app-text-color-light;
$border-color: $neutral-04;

:export {
    primaryColor: $primary-01;
    secondaryColor: $secondary-01;
    accentColor: $accent-01;
    primaryColorDark: $primary-04;
    appBgColor: $app-bg-color;
    appBgColorLight: $app-bg-color-light;
    appBgColorDark: $app-bg-color-dark;
    appTextColor: $app-text-color;
    appTextColorLight: $app-text-color-light;
    successColor: $success-bg;
    warningColor: $warning-bg;
    errorColor: $error-bg;
    white: $white;
    lightGray: $neutral-04;
    gray: $neutral-03;
    darkGrey: $neutral-02;
    black: $black;
    mutedColor: $muted-color;
    borderColor: $border-color;
    semantic2: $semantic-02;
    fieldBackgroundColor: $neutral-05;
    whiteText: $white;
}
