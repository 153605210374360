@import "variables.module.scss";
@import "../../node_modules/react-redux-toastr/src/styles/index.scss";

@import "./listview.scss";
@import "../components/uploader/uploader";
@import "../components/navigationMenu/navigationMenu";
@import "toastr-overrides.scss";

.App,
#root,
body,
html {
    height: 100%;
}

.logo {
    height: 30px;
}

.App {
    height: 100%;
    width: 100vw;
    display: flex;
    background-color: $app-bg-color;

    .toolbar {
        background-color: $app-bg-color-light;
        display: flex;
    }

    .datasets-root {
        overflow: auto;
        width: calc(100vw - 64px);
        display: flex;
        flex-direction: column;
        .no-scope-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            grid-area: uploader;
        }
    }

    .uploader-root {
        display: flex;
        flex-direction: column;
    }

    .upload-container {
        padding: 16px;
        grid-area: uploader;
        flex-grow: 1;
    }
}

.sidebar {
    grid-area: sidebar;
    min-width: 400px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-right: 2px solid white;
}

.dataset-history {
    grid-area: history;
    display: flex;
    padding: 8px;
    flex-direction: column;
    overflow: auto;
}

.sub-header {
    display: flex;
    align-items: center;
    margin-top: 16px;
    height: 48px;
    padding: 0px 16px;

    .action-button {
        margin-left: 4px;
    }
    .title {
        flex-grow: 1;
    }
}

::-webkit-scrollbar-track {
    background-color: #dcdcdc;
}

::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #024f796e;
}

.fidu-info-card {
    background-color: $app-bg-color-light;
    min-width: 850px;
    .image {
        height: 350px;
        background-size: contain;
    }
}

.grow {
    flex-grow: 1;
}

.scope-details-root {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 48px 1fr 1fr;
    grid-template-areas:
        "sidebar top-bar"
        "sidebar uploader"
        "sidebar history";
    height: 100vh;

    .header {
        padding: 8px 16px;
        height: 32px;
        align-items: center;
        background-color: white;
        display: flex;

        border-top: 2px solid $app-bg-color;

        h6 {
            color: $app-text-color;

            flex-grow: 1;
        }

        .button {
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 52px;
            width: 52px;
            transition: 0.2s all;
            cursor: pointer;

            &:hover {
                background-color: #4d5767;
            }
        }
    }

    .top-bar {
        background: $app-bg-color-light;
        color: $app-bg-color-light;
    }

    .scope-info-container {
        overflow: auto;
        display: flex;
        flex-direction: column;
        padding: 0px 16px;
        padding-bottom: 8px;

        .info-section {
            margin-top: 16px;
            padding: 16px;
            padding-top: 8px;
            pointer-events: none;
            background-color: white;
            .info-container {
                display: flex;

                .title {
                    margin-right: 8px;

                    flex-grow: 1;
                }

                .value {
                    font-weight: bold;
                }
            }
        }

        .columns-section {
            overflow: auto;

            margin-top: 8px;

            margin: 0px 8px;

            display: flex;
            flex-direction: column;

            .columns-container {
                overflow: auto;
                display: flex;
                flex-direction: column;
            }

            .column-info-container {
                display: flex;
                border-bottom: 1px solid rgb(231, 231, 231);

                .column-name {
                    padding: 8px;
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-right: 8px;

                    display: flex;
                    align-items: center;
                }

                .grow {
                    flex-grow: 1;
                }

                .column-type {
                    padding: 8px;

                    display: flex;
                    align-items: center;

                    .icon {
                        background-color: $primary-01;
                        padding: 4px;
                        color: white;
                        border-radius: 15px;
                        margin-left: 8px;
                        min-width: 32px;
                        display: flex;
                        justify-content: center;
                        font-size: 12px;
                    }
                }
            }

            .column-info-header {
                display: flex;

                .column-name {
                    padding: 8px 0px;
                    flex-grow: 1;
                    margin-right: 8px;
                    font-weight: bold;
                }

                .column-type {
                    padding: 8px 4px;
                    font-weight: bold;
                }
            }
        }
    }
}

.field {
    margin: 16px 8px;

    input {
        height: auto;
    }

    &.flex {
        display: flex;
        justify-content: space-between;
        & > div {
            width: 48%;
        }
    }
    &.flex-3 {
        display: flex;
        justify-content: space-between;
        & > div {
            width: 31%;
        }
    }
}

.scope-view-root {
    overflow: auto;
    display: flex;
    flex-direction: column;

    .scopes-container {
        padding: 8px;
        overflow: auto;
        .scope {
            background-color: $app-bg-color-light;
            margin-bottom: 4px;
            transition: all 0.1s;
            padding: 8px;

            .scope-header {
                display: flex;
                align-items: center;

                padding-left: 8px;
                cursor: pointer;
                svg {
                    margin-right: 16px;
                    color: $primary-01;
                    width: 20px;
                    height: 20px;
                }

                .date {
                    padding: 8px;
                    color: #14567e;
                    min-width: 90px;
                    text-align: center;
                    font-size: 12px;
                }

                h6 {
                    max-width: 250px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &:hover {
                background-color: $primary-01;
                color: white;
                svg,
                h6 {
                    color: white;
                }
                .scope-header {
                    .date {
                        color: white;
                    }
                }
            }
        }
    }

    .header {
        padding: 8px 16px;
        height: 32px;
        align-items: center;
        background-color: white;
        display: flex;

        border-top: 2px solid $app-bg-color;

        h6 {
            color: $app-text-color;

            flex-grow: 1;
        }

        .button {
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 52px;
            width: 52px;
            transition: 0.2s all;
            cursor: pointer;

            &:hover {
                background-color: #4d5767;
            }
        }
    }

    .list-header {
        display: flex;
        padding: 8px 16px;
        font-size: 14px;

        .type {
            margin-right: 16px;
        }

        .date {
            margin-right: 16px;
        }
    }
}
.no-scope-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    grid-area: uploader;
}
.atlas-dialog {
    .MuiDialog-paper {
        background-color: $app-bg-color;
        min-width: 350px;
    }

    .dialog-header {
        display: flex;
        align-items: center;
        padding: 16px;
        background-color: $app-bg-color-light;
        .dialog-title {
            background-color: $app-bg-color-light;
            flex-grow: 1;
            margin-right: 16px;
        }
    }

    .container {
        padding: 16px;

        .section:not(:last-child) {
            margin-bottom: 16px;
        }

        .field {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
}
